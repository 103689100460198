import invoiceType from "@/components/invoiceType";
import addGoods from "@/components/Goods/addGoods"
import addBuyer from "@/components/Buyer/addBuyer"
import addSeller from "@/components/Seller/addSeller"
import invoiceDetail from "@/components/invoiceDetail"
import qzfImport from "@/components/import/index"
import Pagination from "@/components/Pagination";
import choiceYear from "@/components/choiceYear";
import choiceQuarter from "@/components/choiceQuarter";
import choiceMonth from "@/components/choiceMonth";

import timeSection from "@/components/timeSection";

export default{
  install (Vue, options) {
    Vue.component('invoice-type',invoiceType);//发票类型代码
    Vue.component('add-goods',addGoods);//新增商品
    Vue.component('add-buyer',addBuyer);//新增购方
    Vue.component('add-seller',addSeller);//新增销方
    Vue.component('invoice-detail',invoiceDetail);//发票详情
    Vue.component('qzf-import',qzfImport);//导入
    Vue.component('qzf-pagination',Pagination);
    Vue.component('choice-year',choiceYear);
    Vue.component('choice-quarter',choiceQuarter);
    Vue.component('choice-month',choiceMonth);
    Vue.component('qzf-time',timeSection);
    
  }
}