import Layout from '@/views/layout/index'

// noCache 不缓存
// hidden 不在左侧菜单栏显示
const eaRouter = [
  {
    path: '/redirects',
    component: Layout,
    name: 'redirects',
    redirect: '/redirects/redirectsindex',
    hidden: true,
    meta: {
      title: '跳转页',
      noCache: true,
    },
    children: [
      {
        path: 'redirectsindex',
        component: () => import('@/views/redirect/index'),
        name: 'redirectsindex',
        meta: {
          title: '跳转页子叶',
          noCache: true,
        },
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/homeIndex',
    meta: {
      title: '首页',
      icon: 'icon-home'
    },
    children: [{
      path: 'homeIndex',
      component: () => import ('@/views/home/homeIndex'),
      name: 'homeIndex',
      meta: { title: '首页', icon: 'icon-home' },
    }]
  },
  // {
  //   path: '/invoiceManage',
  //   name: 'index',
  //   redirect: '/index/index1',
  //   component: Layout,
  //   meta: {
  //     title: '部署',
  //     icon: 'icon-creditcard'
  //   },
  //   children: [{
  //     path: 'invoicing',
  //     component: () => import ('@/views/index/invoicing'),
  //     name: 'invoicing',
  //     meta: { title: '部署', icon: 'fapiao' },
  //   },
  //   {
  //     path: 'opsList',
  //     component: () => import ('@/views/index/opsList'),
  //     name: 'opsList',
  //     meta: { title: '设备列表', icon: 'fapiao' },
  //   },
  //   // {
  //   //   path: 'queryPrint',
  //   //   component: () => import ('@/views/index/queryPrint'),
  //   //   name: 'queryPrint',
  //   //   meta: { title: '发票打印', icon: 'fapiao' },
  //   // },
  //   // {
  //   //   path: 'index6',
  //   //   component: () => import ('@/views/index/index6'),
  //   //   name: 'index6',
  //   //   meta: { title: '发票修复', icon: 'fapiao' },
  //   // },
  //   // {
  //   //   path: 'index6',
  //   //   component: () => import ('@/views/index/index7'),
  //   //   name: 'index6',
  //   //   meta: { title: '未上传发票查询', icon: 'fapiao' },
  //   // },
  //   // {
  //   //   path: 'applyInvoiceInfo',
  //   //   component: () => import ('@/views/index/applyInvoiceInfo'),
  //   //   name: 'applyInvoiceInfo',
  //   //   meta: { title: '红字信息表', icon: 'fapiao' },
  //   // },{
  //   //   path: 'statistics',
  //   //   component: () => import ('@/views/index/statistics'),
  //   //   name: 'statistics',
  //   //   meta: { title: '资料统计', icon: 'fapiao' },
  //   // },
  //   ]
  // },
  {
    path: '/newinvoiceManage',
    name: 'index8',
    redirect: '/index/index8',
    component: Layout,
    meta: {
      title: '新版部署',
      icon: 'icon-creditcard'
    },
    children: [{
      path: 'newsinvoicing',
      component: () => import ('@/views/index/newsinvoicing'),
      name: 'newsinvoicing',
      meta: { title: '新版部署', icon: 'fapiao' },
    }]
  }
]

export default eaRouter