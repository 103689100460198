<template>
  <div class="big">
    <div class="top">
      <headers></headers>
    </div>
    <div class="bottom">
      <div class="left">
        <left></left>
      </div>
      <div class="right">
        
        <tags></tags>

        <right></right>
      </div>
    </div>
    
  </div>
</template>
<script>
import headers from './components/header.vue'
import left from './components/left.vue'; 
import right from './components/right.vue'; 
import tags from './components/tags.vue'; 
export default {
  name: 'layout',
  components: {
    left,
    right,
    tags,
    headers,
  },
  setup() {
    
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.big{
  height: 100%;
  .top{
    width: 100%;
    height: 50px;
    background: #ccc;
  }
  .bottom{
    width: 100%;
    height: calc(100% - 50px);
    .left{
      float: left;
      width: 205px;
      height: 100%;
    }
    .right{
      float: right;
      width: calc(100% - 205px);
      height: 100%;
      background: #efefef;
    }
  }
}

</style>