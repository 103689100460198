//注册全局方法
import { ElMessage } from 'element-plus'

export function message(text,type){
  let types = 'success'
  if(type == 1){
    types = "warning"
  }else if(type == 2){
    ElMessage.error(text)
    return
  }
  ElMessage({
    message: text,
    type: types,
  })
};

export function copy(obj){
  if(!obj){
    return
  }
  var newobj = obj.constructor === Array ? [] : {};
  if(typeof obj !== 'object'){
    return
  }
  for(var i in obj){
    newobj[i] = typeof obj[i] === 'object' ? this.$copy(obj[i]) : obj[i];
  }
  return newobj
};

export function fplx(code){
  if(code == '004'){
    return "增值税专用发票"
  }else if(code == '007'){
    return "增值税普通发票"
  }else if(code == '025'){
    return "卷式发票"
  }else if(code == '026'){
    return "电子普通发票"
  }else if(code == '005'){
    return "机动车发票"
  }else if(code == '006'){
    return "二手车销售统一发票"
  }else if(code == '028'){
    return "增值税电子专用发票"
  }
}

// export default{
//   install (Vue, options) {
//     Vue.prototype.$qzfMessage = function(){

//     }
//   }
// }



//原型链上增加方法
// String.prototype.moneyFilter = function(){
//   if(this.indexOf('(') > -1){
//     let amount = this.replace("(", "").replace(")","")
//     return Number((-amount.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
//   }else{
//     return Number((this.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
//   }
// }
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}


export function XMLTOJSON(xml) {
  var x2js = new X2JS();
  var json = x2js.xml_str2json(xml);
  var business = json.business;
  var body = business.body;
  var returndata = body.returndata;
  var kpxx = returndata.kpxx;
  var group = kpxx.group;
  return group
}


// 格式化数据
export function formatData(xml,qdStatus) {
  const _xml = JSON.parse(JSON.stringify(xml))
  console.log(_xml)
  if(qdStatus){
    if (!Array.isArray(_xml.qdxm.group)) {
      _xml.zbmx = [_xml.qdxm.group]
    } else {
      _xml.zbmx = _xml.qdxm.group
    }
  }else{
    if (!Array.isArray(_xml.fyxm.group)) {
      _xml.zbmx = [_xml.fyxm.group]
    } else {
      _xml.zbmx = _xml.fyxm.group
    }
  }


  _xml.ghdwdm = _xml.ghdwsbh
  _xml.xhdwdm = _xml.xhdwsbh
  console.log(_xml)
  // 需要转化为numnber的数据
  _xml.hjje = _xml.hjje ? Number(_xml.hjje) : ''
  _xml.hjzkje = _xml.hjzkje ? Number(_xml.hjzkje) : ''
  _xml.hjzkse = _xml.hjzkse ? Number(_xml.hjzkse) : ''
  _xml.jshj = _xml.jshj ? Number(_xml.jshj) : ''
  _xml.se = _xml.hjse ? Number(_xml.hjse) : ''
  _xml.zhsl = _xml.zhsl ? Number(_xml.zhsl) : ''
  _xml.hjse = _xml.hjse ? Number(_xml.hjse) : ''
  console.log(_xml.hjse);
  _xml.zbmx.forEach(item => {
    item.fphxz = item.fphxz ? Number(item.fphxz) : ''
    item.fpmxxh = item.fpmxxh ? Number(item.fpmxxh) : ''
    item.je = item.je ? Number(item.je) : ''
    item.se = item.se ? Number(item.se) : ''
    item.sl = item.sl ? Number(item.sl) : ''
    item.spdj = item.dj ? Number(item.dj) : ''
    item.spsl = item.spsl ? Number(item.spsl) : ''
  })

  return _xml
}