<template>
  <div class="header-big clearfix"> 
    <!-- <img class="logo" src="@/assets/login__background.svg" alt=""> -->
    <div class="right_header clearfix">
      <div class="header-name">

        <!-- <div class="company-span">
          <i class="iconfont icon-gongsi"></i>
          <el-select @change="changeCompany(2)" class="company" v-model="companyId" filterable placeholder="选择公司">
            <el-option
              v-for="item in companys"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div @click="logout()" class="header-goback">
        <i class="iconfont icon-tuichu"></i>
        {{ $t('header_menu.logout') }}
      </div>
    </div>


    <!-- <el-dialog
      v-model="dialogFormVisible"
      title="选择公司："
      width="60%"
      :before-close="handleClose"
      :show-close="false"
    >
    <el-input size="mini" style="margin-bottom:10px; width:200px" v-model="filterText" placeholder="请输入公司名"></el-input>
    <el-table size="mini" height="500px" :data="companysSearch"  border style="width: 100%" @selection-change="handleSelectionChange" :header-cell-style="{background:'#66b1ff',color:'#ffff'}">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column fixed type="index" width="50" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="公司名" align="center"/>
        <el-table-column prop="tax_no" label="纳税人识别号" align="center"/>
        <el-table-column fixed="right" label="操作" align="center">
          <template v-slot="scope">
            <el-button size="mini" @click="updataCom(scope.row)">确认</el-button>
          </template>
        </el-table-column>
      </el-table>

    
    <template #footer>
      <span class="dialog-footer">
      </span>
    </template>
  </el-dialog> -->



    
  </div>
</template>
<script>
import { companyList, userSaveComId } from "@/api/ea";
export default {
  name: "header",
  data () {
    return {
      dialogFormVisible: false,
      companyId: {},
      companys: [],
      companysSearch: [],
      filterText: "",
    }
  },
  created(){
    let nowTime = new Date().getTime()
    let timeDiff = Number(nowTime) - Number(localStorage.getItem('time'))
    if (timeDiff < 5000) { // 5000
      this.dialogFormVisible = true
    }
    // this.getList()
  },
  watch: {
    filterText(val) {
      this.companysSearch = this.companys.filter(v=>{return v.name.indexOf(this.filterText) > -1})
    }
  },
  methods: {
    handleClose(done) {
      // done();
    },
    updataCom(row){
      this.companyId = row.id
      this.changeCompany(2)
      this.dialogFormVisible = false
    },
    closeAllTags() {
      this.$store.dispatch('tagsView/delAllViews')
      this.$router.push('/')
    },
    changeCompany(type){ 
      let company = this.companys.filter(v=>{return this.companyId == v.id})
      if(company[0]){
        this.$store.dispatch('user/setComInfo', company[0])
        userSaveComId({thisComId: this.companyId}).then(res=>{
          if(res.data.data.returnMsg){
            this.$qzfMessage(res.data.data.returnMsg, 2)
          }
        })
        if(type == 2){
          this.closeAllTags()
        }
      }
    },
    getList(){
      companyList({}).then(res=>{
        if(res.data.msg == "success"){
          this.companys = res.data.data.list
          this.companysSearch = res.data.data.list
          if(this.companys.length != 0){
            this.companyId = this.companys[0].id
            if(this.$store.getters['user/user'].thisComId){
              this.companyId = this.$store.getters['user/user'].thisComId
            }
            this.changeCompany(1)
          }
        }
      })
    },
    logout(){
      this.$store.dispatch('user/LogOut').then(() => {
        location.reload()
      })
    }
  },
  setup() {
    
  },
}
</script>

<style>
.company-span .el-select .el-input__inner{
  border:0 !important;
  padding-right: 30px;
}
.company-span .el-input__inner{
  padding: 0 6px !important;
}
.company-span .el-input__inner{
  position: relative;
  top: -3px;
}
.company-span .el-input__suffix {
  top: -2px;
}
</style>
<style lang="scss" scoped>
.header-big{
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 30px;
  background:white;
  border-bottom: 1px solid #eee;
  .logo{
    // position: absolute;
    // top: 3px;
    // left: 27px;
    float: left;
    width: 126px;
    height: 48px;
    font-size: 12px;
  }
  .right_header{
    float: right;
    padding-top: 10px;
  }
  .header-name{
    // position: absolute;
    // right: 100px;
    // top: 15px;
    float: left;
    i{
      vertical-align: top;
    }
    span{
      vertical-align: top;
      font-size: 13px;
      margin-left: 5px;
    }
  }
  .header-goback{
    // position: absolute;
    // top: 18px;
    // right: 20px;
    float: left;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    line-height: 28px;
  }
}
.company-span{
  height: 30px;
  i{
    line-height: 30px;
    color: #666;
  }
}
.company{
  min-width: 300px;
  height: 30px;
}
.el-icon-printer{
  vertical-align: sub;
}
</style>