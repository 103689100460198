import request from '@/utils/request'


export function uploadFile(formdata) {
    return request({
        url: 'api/v1/uploadFile',
        method: 'post',
        data: formdata,
        config: {
            headers: { 'Content-Type': 'multipart/form-data' }
        }
    })
}

//更新公司信息
export function updateCompany(data) {
    return request({
        url: 'api/v1/company/updateCompany',
        method: 'post',
        data
    })
}
//同步登记信息
export function companyTbdjxx(data) {
    return request({
        url: 'api/v1/company/companyTbdjxx',
        method: 'post',
        data
    })
}
//获取商品列表
export function getGoodList(data) {
    return request({
        url: 'api/v1/invoice/getGoodList',
        method: 'post',
        data
    })
}
//新增修改商品信息
export function addGood(data) {
    return request({
        url: 'api/v1/invoice/addGood',
        method: 'post',
        data
    })
}
//删除商品
export function delGood(data) {
    return request({
        url: 'api/v1/invoice/delGood',
        method: 'post',
        data
    })
}
//获取税种税目信息
export function getSzsmxx(data) {
    return request({
        url: 'api/v1/invoice/getSzsmxx',
        method: 'post',
        data
    })
}
//获取公司列表
export function companyList(data) {
    return request({
        url: 'api/v1/company/list',
        method: 'post',
        data
    })
}

//公司删除
export function delCompany(data) {
    return request({
        url: 'api/v1/company/delCompany',
        method: 'post',
        data
    })
}

//添加公司
export function addCompany(data) {
    return request({
        url: 'api/v1/company/addCompany',
        method: 'post',
        data
    })
}

//用户列表
export function userList(data) {
    return request({
        url: 'api/v1/company/userList',
        method: 'post',
        data
    })
}

//用户删除
export function delUser(data) {
    return request({
        url: 'api/v1/company/delUser',
        method: 'post',
        data
    })
}

//获取税收分类编码
export function inventoryClass(data) {
    return request({
        url: 'api/v1/invoice/inventoryClass',
        method: 'post',
        data
    })
}

//智能赋码
export function znfm(data) {
    return request({
        url: 'api/v1/invoice/znfm',
        method: 'post',
        data
    })
}

//购方信息
//购方单位列表
export function getCorpCustomerList(data) {
    return request({
        url: 'api/v1/invoice/getCorpCustomerList',
        method: 'post',
        data
    })
}
//新增购方单位信息
export function addCorpCustomer(data) {
    return request({
        url: 'api/v1/invoice/addCorpCustomer',
        method: 'post',
        data
    })
}
//删除购方单位
export function delCorpCustomer(data) {
    return request({
        url: 'api/v1/invoice/delCorpCustomer',
        method: 'post',
        data
    })
}
//销方信息
//销方单位列表
export function getCorpList(data) {
    return request({
        url: 'api/v1/invoice/getCorpList',
        method: 'post',
        data
    })
}
//新增销方单位信息
export function addCorp(data) {
    return request({
        url: 'api/v1/invoice/addCorp',
        method: 'post',
        data
    })
}
//删除销方单位
export function delCorp(data) {
    return request({
        url: 'api/v1/invoice/delCorp',
        method: 'post',
        data
    })
}
export function getBaseDict(data) {
    return request({
        url: 'api/v1/invoice/getBaseDict',
        method: 'post',
        data
    })
}

//商品导入
export function addGoods(data) {
    return request({
        url: 'api/v1/invoice/addGoods',
        method: 'post',
        data
    })
}

//发送邮件
export function elecInvoiceEmail(data) {
    return request({
        url: 'api/v1/invoice/elecInvoiceEmail',
        method: 'post',
        data
    })
}

//新的发送邮件
export function InvoicePdf(data) {
    return request({
        url: 'api/v1/invoice/InvoicePdf',
        method: 'post',
        data
    })
}

// 下载专票pdf ofd
export function elecInvoiceZpOfdPdf(data) {
    return request({
        url: 'api/v1/invoice/elecInvoiceZpOfdPdf',
        method: 'post',
        data
    })
}
// 下载普票pdf ofd
export function elecInvoicePdf(data) {
    return request({
        url: 'api/v1/invoice/elecInvoicePdf',
        method: 'post',
        data
    })
}

// 批量下载pdf ofd
export function batchInvoicePdf(data) {
    return request({
        url: 'api/v1/invoice/batchInvoicePdf',
        method: 'post',
        data
    })
}

//导出发票
export function invoiceExcel(data) {
    return request({
        url: 'api/v1/invoice/invoiceExcel',
        method: 'post',
        data
    })
}

//获取邮箱
export function corpCustomerInfo(data) {
    return request({
        url: 'api/v1/invoice/corpCustomerInfo',
        method: 'post',
        data
    })
}

//发票修复
export function fpxf(data) {
    return request({
        url: 'api/v1/invoice/fpxf',
        method: 'post',
        data
    })
}

//更新最新发票
export function updateInvoice(data) {
    return request({
        url: 'api/v1/invoice/updateInvoice',
        method: 'post',
        data
    })
}

//验签发票上传发票
export function yanqian(data) {
    return request({
        url: 'api/v1/invoice/yanqian',
        method: 'post',
        data
    })
}

//修改密码
export function userPassWordSave(data) {
    return request({
        url: 'api/v1/company/userPassWordSave',
        method: 'post',
        data
    })
}

//修改最后选择公司
export function userSaveComId(data) {
    return request({
        url: 'api/v1/company/userSaveComId',
        method: 'post',
        data
    })
}
//资料统计
export function sum(data) {
    return request({
        url: 'api/v1/invoice/sum',
        method: 'post',
        data
    })
}
//资料统计 打印
export function print(data) {
    return request({
        url: 'api/v1/print/invoice/sum',
        method: 'post',
        data
    })
}

//导入签章
export function importQz(data) {
    return request({
        url: 'api/v1/invoice/importQz',
        method: 'post',
        data
    })
}


//导入清单
export function importInvoiceQd(data) {
    return request({
        url: 'api/v1/invoice/importInvoiceQd',
        method: 'post',
        data
    })
}

//同步发票信息到商品单位
export function syncInfo(data) {
    return request({
        url: 'api/v1/invoice/syncInfo',
        method: 'post',
        data
    })
}

//同步智云新加的公司信息
export function contrastCompanyZy(data) {
    return request({
        url: 'api/v1/company/contrastCompanyZy',
        method: 'post',
        data
    })
}

//购方纳税人列表
export function collectionCorp(data) {
    return request({
        url: 'api/v1/invoice/collectionCorp',
        method: 'post',
        data
    })
}

//勾选认证
export function gxrz(data) {
    return request({
        url: 'api/v1/company/gxrz',
        method: 'post',
        data
    })
}

//新增代理记账公司
export function addOrg(data) {
    return request({
        url: 'api/v1/company/addOrg',
        method: 'post',
        data
    })
}

//人员管理新增员工
export function addPlainUser(data) {
    return request({
        url: 'api/v1/user/addPlainUser',
        method: 'post',
        data
    })
}

//给账号分配公司
export function assignmentCom(data) {
    return request({
        url: 'api/v1/user/assignmentCom',
        method: 'post',
        data
    })
}
//盘组设备管理
// 查看所有盘组设备列表
export function zyDeviceList(data) {
    return request({
        url: 'api/v1/zy/zyDeviceList',
        method: 'post',
        data
    })
}

// 税盘设备更新列表
export function zyDeviceUpdateList(data) {
    return request({
        url: 'api/v1/zy/zyDeviceUpdateList',
        method: 'post',
        data
    })
}

// 添加税盘
export function zyDeviceUpdates(data) {
    return request({
        url: 'api/v1/zy/zyDeviceUpdates',
        method: 'post',
        data
    })
}

// 修改mac地址
export function saveOrg(data) {
    return request({
        url: 'api/v1/user/saveOrg',
        method: 'post',
        data
    })
}