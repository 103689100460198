<template>
  <div class="left-big">
    <el-menu
      ref="menus"
      default-active="2"
      class="el-menu-vertical-demo"
      :default-openeds="opens"
      @open="handleOpen"
      @close="handleClose"
    >
      <!-- 仅支持2级菜单 路由必须写2级的 -->
      <template v-for="(item,index) in routers">
        <!-- style="background: #6ABFBA;" -->
        <el-sub-menu style="background: #6ABFBA !important;color: #fff !important;" v-if="item.children && item.children.length > 1 && !item.hidden" :index="index">
          <template #title>
            <!-- <i class="el-icon-location"></i> -->
            <i class="iconfont" style="color: #fff !important;" :class="item.meta.icon"></i>
            <span style="color: #fff !important;">{{item.meta.title}}</span>
          </template>
          
          <!-- <router-link v-for="(itemC,indexC) in item.children" class="router-link" :to="`${item.path}/${itemC.path}`"> -->
          <router-link v-for="(itemC,indexC) in item.children" class="router-link" :to="{name: itemC.name}">
            <el-menu-item v-if="!itemC.hidden" :index="`${index}-${indexC}`">{{itemC.meta.title}}</el-menu-item>
          </router-link>
        </el-sub-menu>

        <router-link v-else-if="item.children && item.children.length == 1 && !item.hidden" class="router-link" :to="{name: item.children[0].name}">
          <el-menu-item :index="index">
            <i class="iconfont " :class="item.meta.icon"></i>
            <span>{{item.children[0].meta.title}}</span>
          </el-menu-item>
        </router-link>

        <router-link v-else-if="!item.hidden" class="router-link" :to="item.path">
          <el-menu-item :index="index">
            <!-- <i class="el-icon-menu"></i> -->
            <!-- <i class="iconfont " :class="item.meta.icon"></i> -->
            <span>{{item.meta.title}}</span>
          </el-menu-item>
        </router-link>
      </template>
    </el-menu>

  </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'left',
  data () {
    return {
      // opens: ["/index", "/setting"], // 设置菜单栏展开, 默认展开子菜单 
      opens: [0, 1, 2, 3, 4, 5], // 设置菜单栏展开, 默认展开子菜单 
      routers: store.getters['router/asyncRouters'],

    }
  },
  created () {
    console.log(this.routers);
    this.routers.map(v=>{
      console.log(v)
    })
  },
  methods: {
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
  }
}
</script>
<style lang="scss" scoped>
.iconfont{
  font-size: 22px;
  margin-right: 5px;
}
.left-big{
  overflow-y: auto;
  height: 100%;
}

/*滚动条宽度*/
      .left-big::-webkit-scrollbar {
        width: 4px;
      }
      /*滚动条里面小方块*/
      .left-big::-webkit-scrollbar-thumb {
		  border-radius: 5px;
		  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		  background: rgba(100, 100, 100, 0.2);
		}
		/*滚动条里面轨道*/
		// .left-big::-webkit-scrollbar-track {
		//   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		//   border-radius: 0;
		//   background: rgba(0, 0, 0, 0.1);
		// }

</style>
<style lang="scss">
.router-link{
 text-decoration:none;
}
.el-sub-menu__title:hover{
   background-color: #6ABFBA !important; 
}
//子级的类名
// .el-menu-item:hover {
//   background-color: rgb(3, 19, 33) !important;
// }

</style>
<style>
.el-sub-menu__title{
  line-height:42px !important;
  height: 42px !important;
}
.el-menu-item{
  line-height:42px !important;
  height: 42px !important;
}
</style>