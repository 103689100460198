<template>
  <el-dialog v-model="dialogVisible" title="发票明细" width="63%" :before-close="handleClose">
    <div class="big_box2">
      <div class="header">
        <ul class="left">
          <li>征收方式：{{row.zsfs}}</li>
          <li>订单来源：<span style="font-size:1px">{{row.from}}</span></li>
          <li>申请人：<span style="font-size:1px">{{row.ghdwmc}}</span></li>
          <li>机器编号：<span style="font-size:1px">{{row.jqbh}}</span></li>
        </ul>
        <h1 class="title">{{$fplx(row.fplxdm)}}</h1>
        <ul class="right">
          <li>发票代码：<span style="font-size:1px">{{row.fpdm}}</span></li>
          <li>发票号码：<span style="font-size:1px">{{row.fphm}}</span></li>
          <li>开票日期：<span style="font-size:1px">{{row.kprq}}</span></li>
          <li>校验码：<span style="font-size:1px">{{row.jym}}</span></li>
        </ul>
      </div>
      <div class="content">
        <el-row :span="24" class="row_box2">
          <el-col :xs="1" :sm="1" :lg="1" class="sideBar_title">
            <div class="grid-content height_font">
                <p>购货单位</p>
            </div>
          </el-col>
          <el-col :xs="11" :sm="11" :lg="11">
            <div>
              <ul class="content_content">
                <li style="margin-bottom:2px;letter-spacing:26px">名  称<span style="font-size:1px">：{{row.ghdwmc}}</span></li>
                <li style="margin-bottom:2px;letter-spacing:5.5px">纳税人识别号<span style="font-size:1px">：{{row.ghdwsbh}}</span></li>
                <li style="margin-bottom:2px;letter-spacing:9px">地址、电话<span style="font-size:1px">：{{row.ghdwdzdh}}</span></li>
                <li style="margin-bottom:2px;letter-spacing:5.5px">开户行及账号<span style="font-size:1px">：{{row.ghdwyhzh}}</span></li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="1" :sm="1" :lg="1" class="sideBar_title">
            <div class="grid-content height_font">
            </div>
          </el-col>
          <el-col :xs="11" :sm="11" :lg="11">
            <ul class="content_content">
              <li style="margin-bottom:2px;letter-spacing:9px">原发票代码<span style="font-size:1px;letter-spacing:0px">：{{row.yfpdm}}</span></li>
              <li style="margin-bottom:2px;letter-spacing:9px">原发票号码<span style="font-size:1px;letter-spacing:0px">：{{row.yfphm}}</span></li>
              <li style="margin-bottom:2px;letter-spacing:26px">手 机<span style="font-size:1px;letter-spacing:0px">：{{row.ghdwmc}}</span></li>
              <li style="margin-bottom:2px;letter-spacing:26px">邮 箱<span style="font-size:1px;letter-spacing:0px">：{{row.ghdwsbh}}</span></li>
            </ul>
          </el-col>
          <el-col :xs="24" :sm="24" :lg="24">
            <el-table :data=row.item border style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange" size="mini" :header-cell-style="{background:'#008000',color:'#ffff'}">
              <el-table-column label="货物或应税劳务名称" min-width="150" align="center" prop="spmc">
              </el-table-column>
              <el-table-column label="规格型号" min-width="100" align="center" prop="ggxh">
              </el-table-column>
              <el-table-column label="单位" min-width="100" align="center" prop="dw">
              </el-table-column>
              <el-table-column label="数量" min-width="100" align="center" prop="spsl">
              </el-table-column>
              <el-table-column label="单价(不含税)" min-width="110" align="center" prop="dj">
              </el-table-column>
              <el-table-column label="金额(不含税)" min-width="120" align="center" prop="je">
              </el-table-column>
              <el-table-column label="税率" min-width="120" align="center" prop="sl">
                <template v-slot="scope">
                  <span v-if="scope.row.lslbs == '1'">免税</span>
                  <span v-else-if="scope.row.lslbs == '2'">不征税</span>
                  <span v-else>{{scope.row.sl}}</span>
                </template>
              </el-table-column>
              <el-table-column label="税额" min-width="120" align="center" prop="se">
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="6" :sm="6" :lg="6"><p style="letter-spacing:30px;text-align:center;margin-left:23px">合计</p></el-col>
          <el-col :xs="6" :sm="6" :lg="6"><span class="money_style"></span></el-col>
          <el-col :xs="6" :sm="6" :lg="6"><p style="letter-spacing:5px;margin-left:12px">金额：<span class="money_style">{{row.hjje}}</span></p></el-col>
          <el-col :xs="6" :sm="6" :lg="6"><p style="letter-spacing:5px;margin-left:12px">税额：<span class="money_style">{{row.hjse}}</span></p></el-col>
          <el-col :xs="6" :sm="6" :lg="6"><p style="letter-spacing:10px;text-align:center;margin-left:20px;">价税合计(大写)：</p></el-col>
          <el-col :xs="6" :sm="6" :lg="6"><span class="money_style">{{upDigit(row.jshj)}}</span></el-col>
          <el-col :xs="12" :sm="12" :lg="12"><p style="letter-spacing:2px;margin-left:12px">(小写) ：<span class="money_style">{{row.jshj}}</span></p></el-col>
          <el-col :xs="1" :sm="1" :lg="1" class="sideBar_title">
            <div class="grid-content height_font">
              <p>销货单位</p>
            </div>
          </el-col>
          <el-col :xs="11" :sm="11" :lg="11">
            <ul class="content_content">
              <li style="margin-bottom:2px;letter-spacing:26px">名  称<span style="font-size:1px">：{{row.xhdwmc}}</span></li>
              <li style="margin-bottom:2px;letter-spacing:5.5px">纳税人识别号<span style="font-size:1px">：{{row.xhdwsbh}}</span></li>
              <li style="margin-bottom:2px;letter-spacing:9px">地址、电话<span style="font-size:1px">：{{row.xhdwdzdh}}</span></li>
              <li style="margin-bottom:2px;letter-spacing:5.5px">开户行及账号<span style="font-size:1px">：{{row.xhdwyhzh}}</span></li>
            </ul>
          </el-col>
          <el-col :xs="1" :sm="1" :lg="1" class="sideBar_title">
            <div class="grid-content height_font"><p>备  注</p></div>
          </el-col>
          <el-col :xs="11" :sm="11" :lg="11">
            {{row.bz}}
          </el-col>
          <el-col :xs="24" :sm="24" :lg="24" style="border-bottom:none">
            <el-form :inline="true" label-width="100px" size="mini">
              <el-form-item label="收款人：" style="margin-right:178px">{{row.skr}}</el-form-item>
              <el-form-item label="复核人：" style="margin-right:176px">{{row.fhr}}</el-form-item>
              <el-form-item label="开票人：">{{row.kpr}}</el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
// <invoice-type v-model:code="temp.fplx"></invoice-type>
export default {
  name: 'invoiceDetail',
  props: {
      row:{}
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
    }
  },
  computed: {
    listStr(){
      return this.$store.getters['user/comInfo'].fplxdm
    }
  },
  watch:{
    listStr(){
    },
  },
  created () {
  },
  methods: {
    changeCode(){
      this.$emit('update:code', this.code)
      this.$emit('success', this.code)
    },
    upDigit(n){  
      var fraction = ['角','分'];  
      var digit = ['零','壹','贰','叁','肆','伍','陆','柒','捌','玖'];  
      var unit = [ ['圆','万','亿'],['','拾','佰','仟']  ];  
      var head = n < 0? '负': '';  
      n = Math.abs(n); 
      var s = ''; 
      for (var i = 0; i < fraction.length; i++)   
      {  
          s += (digit[Math.floor(n * 10 * Math.pow(10,i)) % 10] + fraction[i]).replace(/零./,'');  
      }  
      s = s || '整';  
      n = Math.floor(n); 
      for (var i = 0; i < unit[0].length && n > 0; i++)   
      {  
        var p = '';  
        for (var j = 0; j < unit[1].length && n > 0; j++)   
        {  
            p = digit[n % 10] + unit[1][j] + p;  
            n = Math.floor(n / 10);  
        }  
        s = p.replace(/(零.)*零$/,'').replace(/^$/,'零')  + unit[0][i] + s;  
      }  
      return head + s.replace(/(零.)*零圆/,'圆').replace(/(零.)+/g,'零').replace(/^整$/,'零圆整');  
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #008000;
  margin-top: 10px;
  border-right: none;
  font-size: 1px;
  // height: 684px;
}
.big_box2 {
  width: 982px;
  padding:44px 64px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: -19px auto;
  position: relative;
  border:1px solid #ddd;
}
.height_font {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: whitesmoke;
  p {
    width: 20px;
    margin: 0 auto;
    height: 100%;
    line-height: 23px;
    padding-top: 11px;
    font-size: 2px;
  }
}
.el-col {
  border-right: 1px solid #008000;
  border-bottom: 1px solid #008000;
}
.right{
  position: absolute;
  list-style: none;
  top: 58px;
  right: 110px;
  font-size: 1px;
}
.left{
  list-style: none;
  position: absolute;
  top: 58px;
  left: 66px;
  font-size: 1px;
}
.header{
    height: 73px;
}
.title{
    font-family: cursive;
    letter-spacing: 4px;
    color: rgb(0, 128, 0);
    border-bottom: 4px double green;
    display: inline-block;
    margin-left: 325px;
    margin-top: 20px;
}
.el-form{
  margin: 0px 20px 4px 90px;
}
.el-form-item--mini.el-form-item{
  margin-bottom: -13px;
}
.el-textarea{
  width: 94%;
  margin: 10px 10px 10px 10px;
}
.el-textarea__inner{
  min-height: 166px;
}
 
.el-dialog__header{
    background-color: #f8f8f8;
}
.sideBar_title{
  background-color: whitesmoke;
}
.money_style{
  letter-spacing:1px;
  color: red;
}
.content_content{
  list-style: none;
  margin: 12px 10px 10px 16px; 
  span{
    letter-spacing: 0px;
  }      
}
</style>

<style lang="scss">
.el-form-item--mini .el-form-item__label{
  font-size: 1px;
}
.el-form-item--mini .el-form-item__content{
  font-size: 1px;
}
.el-dialog__title{
  font-size: 15px;
}
.el-dialog__header{
  background-color: whitesmoke;
}
</style>
