import request from '@/utils/request'

export function loginByUsername(username, password) {
  const data = {
    username,
    password,
  }
  return request({
    url: '/public/login',
    method: 'post',
    data
  })
}
export function logout() {
  return request({
    url: '/apiOps/v1/logout',
    method: 'get'
  })
}

export function getUserInfo(data) {
  return request({
    url: '/api/app/userInfo',
    method: 'post',
    data
  })
}
